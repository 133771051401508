import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Button, Icon, Tooltip, Input } from "antd";
import "../../../public/css/login.css";
// import LogoutStore from '../MainFrame/store/LogoutStore';
import SignupAction from "../SignUp/action/SignUpAction";
import SignupStore from "../SignUp/store/SignupStore";
// import cookie from '../../utils/cookie';
import { ButtonElement } from "../../components/ButtonElement";
import languageFile from "../../../public/languages/Translation";
import _ from "lodash";
import urlConfig, { urlName } from "../../config/index";
import MicrosoftLinkAction from "../Login/action/MicrosoftLinkAction";
import MicrosoftLinkStore from "../Login/store/MicrosoftLinkStore";
import MicrosoftLoginAction from "../Login/action/MicrosoftLoginAction";
import cookie from "../../utils/cookie";
import GoogleLogin from "react-google-login";
import GoogleLoginAction from "../Login/action/GoogleLoginAction";
import LoginStore from "../Login/store/LoginStore";
import GetProfileDetailsAction from "../AuthorisePage/action/GetProfileDetailsAction";
import UpdateEmailAction from "../Login/action/UpdateEmailAction";
import { googleKey2domains, googleKey3domains, googleKey4domains } from "./domainList";
import GetIntegrationAction from "../IntegrationControl/action/GetIntegrationAction";
import GetIntegrationStore from "../IntegrationControl/store/GetIntegrationStore";

import {
	availableLanguages,
	language2letterCode,
	languageDropDown,
	languageDropDownDomains,
} from "../../utils/languageConstants";
import "../Login/style.scss";
import { LCMessage } from "../../frameWork";
import languageData from "../../utils/defaultLanguageConfig";
import getSVG from "../../utils/commonSVG";
var passwordValidator = require("password-validator");
var analytics = window.analytics;
const passwordInvalidMessage =
	"Passwords must be atleast 8 characters long and maximum 20 characters, not contain spaces and contain characters from three of the following four categories: uppercase letters, lowercase letters, numeric (0-9), and special characters(!@#$%^&*).";
const schema = new passwordValidator();

schema
	.is()
	.min(8) // Minimum length 8
	.is()
	.max(20) // Maximum length 20
	.has()
	.uppercase() // Must have uppercase letters
	.has()
	.lowercase() // Must have lowercase letters
	.has()
	.digits() // Must have digits
	.has()
	.not()
	.spaces() // Should not have spaces
	.has()
	.symbols();

class SignUp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			firstName: "",
			lastName: "",
			validationObject: {},
			signupSuccess: true,
			currentlanguage: localStorage.getItem("language"),
			readSettings: false,
			whiteLabelImages: {},
			languagesList: [],
			isBusinessEmail: false,
			domainErrorMessages: "Please enter a business email address",
			showPass: false,
			// company: '',
			loading: false,
			favIcon: '',
		};
		this.onSignUpResponse = this.onSignUpResponse.bind(this);
		this.changelanguage = this.changelanguage.bind(this);
		this.checkPasswordStrength = this.checkPasswordStrength.bind(this);
		// this.checkPasswordMatch = this.checkPasswordMatch.bind(this);
		this.signUp = this.signUp.bind(this);
		this.userDetailsChange = this.userDetailsChange.bind(this);
		this.onSignup = this.onSignup.bind(this);
		this.validate = this.validate.bind(this);
	}

	componentWillMount() {
		SignupStore.bind(this.onSignUpResponse);
		MicrosoftLinkStore.bind(this.microsoftLinkReceived);
		LoginStore.bind(this.onLoginResponse);
		GetIntegrationStore.bind(this.onGetIntegrations);
		GetIntegrationAction.getintegration(urlConfig.urlName);
	}

	componentWillUnmount() {
		SignupStore.unbind(this.onSignUpResponse);
		MicrosoftLinkStore.unbind(this.microsoftLinkReceived);
		LoginStore.unbind(this.onLoginResponse);
		GetIntegrationStore.unbind(this.onGetIntegrations);
	}
	onLoginResponse = () => {
		if (LoginStore.getSignInResponse().token) {
			// var myElement = document.querySelector("#google-signin");
			// myElement.style.display = "none";
			cookie.createCookie("lctoken", LoginStore.getSignInResponse().token, 2);
			cookie.createCookie("userName", LoginStore.getSignInResponse().name, 2);
			cookie.createCookie("userId", LoginStore.getSignInResponse().userId, 2);

			if (
				cookie.readCookie("inviteeId") &&
				cookie.readCookie("inviteeId") !== cookie.readCookie("userId")
			) {
				UpdateEmailAction.update({
					profileId: cookie.readCookie("profileId"),
					invitedEmail: cookie.readCookie("inviteeId"),
					loginEmail: cookie.readCookie("userId"),
				});
				cookie.eraseCookie("inviteeId");
				cookie.eraseCookie("profileId");
			}
			//it will work when inbox tab active
			if (this.props.location.search.includes("?redirecturl")) {
				// if (Object.prototype.hasOwnProperty.call(this.props.location.search, 'redirecturl')) {
				localStorage.setItem(
					"profileId",
					this.props.location.search.split("&profileId=")[1]
				);
				GetProfileDetailsAction.getProfileDetails(
					this.props.location.search.split("&profileId=")[1], urlName
				);
			} else if (this.props.location.search.includes("?reviewurl")) {
				// } else if (Object.prototype.hasOwnProperty.call(this.props.location.search, 'reviewurl')) {
				localStorage.setItem(
					"profileId",
					this.props.location.search.split("&profileId=")[1]
				);
				GetProfileDetailsAction.getProfileDetails(
					this.props.location.search.split("&profileId=")[1], urlName
				);
			} else {
				// this.props.history.push('/authorize')
				window.location.href = "/authorize";
			}
		} else {
			this.setState({
				signupSuccess: false,
				errorMessage: "Please enter a business email.",
			});
		}
	};
	componentDidMount() {
		MicrosoftLinkAction.getLink();
		// var userInfo = this.props.location.search;
		var userInfo = this.props.location.pathname;
		var userExists = false;
		if (userInfo) {
			var info = userInfo.split("&");
			var userEmail = info[0].split("=");
			if (userEmail[1]) {
				let validationObject = {};
				validationObject.email = false;
				this.setState({ email: userEmail[1], validationObject, userName: '', password: '' });
			}
			var userExistsArray = [];
			if (info.length > 0) {
				userExistsArray = info.filter(function (item, index) {
					return item.indexOf("userExists") > -1;
				});
				if (
					userExistsArray.length > 0 &&
					userExistsArray[0].split("=")[1] === "true"
				) {
					userExists = true;
				}
			}
		}
		if (userExists) {
			this.props.history.push("/login");
		}
	}
	onGetIntegrations = () => {
		let blockedDomains = "^([\\w-\\.]+@";
		let blockedPublicEmailDomains =
			(GetIntegrationStore.getResponse() &&
				GetIntegrationStore.getResponse().blockedPublicEmailDomains) ||
			[];
		if (blockedPublicEmailDomains.length) {
			blockedPublicEmailDomains = blockedPublicEmailDomains.map((v) =>
				v.toLowerCase()
			);
		}
		let blockedSpamDomains =
			(GetIntegrationStore.getResponse() &&
				GetIntegrationStore.getResponse().blockedSpamDomains) ||
			[];
		if (blockedSpamDomains.length) {
			blockedSpamDomains = blockedSpamDomains.map((v) => v.toLowerCase());
		}
		let blockedTopLevelDomains =
			(GetIntegrationStore.getResponse() &&
				GetIntegrationStore.getResponse().topLevelDomains) ||
			[];
		if (blockedTopLevelDomains.length) {
			blockedTopLevelDomains = blockedTopLevelDomains.map((v) =>
				v.toLowerCase()
			);
		}
		let blockedDomainsList = [
			...blockedPublicEmailDomains,
			...blockedSpamDomains,
			...blockedTopLevelDomains,
		];
		for (let i = 0; i < blockedDomainsList.length; i++) {
			blockedDomains = blockedDomains + "(?!" + blockedDomainsList[i] + ")";
		}
		blockedDomains = blockedDomains + "([\\w-]+\\.)+[\\w-]{2,5})?$";
		let signupImage =
			(GetIntegrationStore.getResponse().whiteLabelImages.signupBackground &&
				GetIntegrationStore.getResponse().whiteLabelImages.signupBackground +
				"?time=" +
				Date.now()) ||
			`/images/${urlConfig.urlName}/login.png`;
		let domainImage =
			(GetIntegrationStore.getResponse().whiteLabelImages.domainLogo &&
				GetIntegrationStore.getResponse().whiteLabelImages.domainLogo +
				"?time=" +
				Date.now()) ||
			`/images/${urlConfig.urlName}/login-text.png`;
		this.setState({
			readSettings: false,
			signupBackground: signupImage,
			domainLogo: domainImage,
			languagesList: GetIntegrationStore.getResponse().domainLanguages,
			signUpPosition:
				GetIntegrationStore.getResponse().signUpPosition || "Default",
			marketingMessage:
				GetIntegrationStore.getResponse().marketingMessage || "",
			htmlStyle: GetIntegrationStore.getResponse().style || "",
			isBusinessEmail:
				GetIntegrationStore.getResponse().emailValidation || false,
			blockedPublicEmailDomains: blockedPublicEmailDomains,
			blockedSpamDomains: blockedSpamDomains,
			blockedDomains: blockedDomains,
			blockedTopLevelDomains: blockedTopLevelDomains,
			blockedDomainErrorMessages:
				GetIntegrationStore.getResponse().blockedDomainErrorMessages,
		});
		if (this.state.signUpPosition != "Default") {
			document.getElementById("style").innerHTML = this.state.htmlStyle;
			document.getElementById("message").innerHTML =
				this.state.marketingMessage.substring(0, 200);
		}
		if (GetIntegrationStore.getResponse().whiteLabelImages.favicon) {
			let domainName = urlConfig.urlName;
			let link =
				document.querySelector("link[rel*='icon']") ||
				document.createElement("link");
			link.type = "image/x-icon";
			link.rel = "shortcut icon";
			link.href = GetIntegrationStore.getResponse().whiteLabelImages.domainIcon
				? GetIntegrationStore.getResponse().whiteLabelImages.domainIcon +
				"?time=" +
				Date.now()
				: languageData.languageData[domainName].href;

			document.title = GetIntegrationStore.getResponse().pageTitle
				? GetIntegrationStore.getResponse().pageTitle
				: languageData.languageData[domainName].title;
			document.getElementsByTagName("head")[0].appendChild(link);

			var meta =
				document.querySelector("meta[name*='description']") ||
				document.createElement("meta");
			meta.name = "description";
			meta.content = GetIntegrationStore.getResponse().pageDescription
				? GetIntegrationStore.getResponse().pageDescription
				: languageData.languageData[domainName].description;
			document.getElementsByTagName("head")[0].appendChild(meta);
			this.setState({ favIcon: link.href })
		}
	};

	microsoftLinkReceived = () => {
		this.setState({
			micUrl: MicrosoftLinkStore.getResponse().url,
		});
	};
	onSignUpResponse() {
		this.setState({ loading: false })
		if (SignupStore.getSignInResponse() === 200) {
			this.setState({
				signupSuccess: true,
			});
			analytics.track("Signed Up", {
				email: SignupStore.getSignInResponse().userName,
			});
			this.props.history.push("/login");
		} else if (
			SignupStore.getSignInResponse().data &&
			SignupStore.getSignInResponse().data.status === 500
		) {
			this.setState({
				signupSuccess: false,
				errorMessage: "Internal error occured. Please try after sometime",
			});
		} else if (
			SignupStore.getSignInResponse().data &&
			SignupStore.getSignInResponse().data.status === 409
		) {
			if (window.location.href.indexOf("reputationmanager") === -1) {
				this.setState({
					signupSuccess: false,
					errorMessage:
						"This email id already has an account . Please use another email.",
				});
			} else {
				this.setState({
					signupSuccess: false,
					errorMessage:
						"This email id already has an account. Please use another email.",
				});
			}
		} else if (
			SignupStore.getSignInResponse().data &&
			SignupStore.getSignInResponse().data.status === 400 &&
			SignupStore.getSignInResponse().data.subCode === 1
		) {
			this.setState({
				signupSuccess: false,
				errorMessage:
					"Your password, although valid, is very common and easy to figure out. Please use a stronger password!",
			});
		} else if (
			SignupStore.getSignInResponse().data &&
			SignupStore.getSignInResponse().data.status === 400 &&
			SignupStore.getSignInResponse().data.subCode === 2
		) {
			this.setState({
				signupSuccess: false,
				errorMessage:
					"Please use a new password, that has not been previously used.",
			});
		} else if (
			SignupStore.getSignInResponse().data &&
			SignupStore.getSignInResponse().data.hasOwnProperty("message")
		) {
			this.setState({
				signupSuccess: false,
				errorMessage: SignupStore.getSignInResponse().data.message,
			});
		} else {
			this.setState({
				signupSuccess: false,
				errorMessage: "Sign up Failed",
			});
		}
	}

	userDetailsChange(field, event) {
		var validationObject = this.state.validationObject;
		validationObject[field] = this.validate(field, event.target.value);

		this.setState(
			{
				validationObject: validationObject,
				[field]: event.target.value.trim(),
				signupSuccess: true
			},
			function () {
				// alert(field+this.state.passwordValid)
				if (field === "password") {
					this.checkPasswordStrength();
				}
				// else if (field === "confirmPassword") {
				// 	this.checkPasswordMatch();
				// }
			}
		);
	}
	validateFields = () => {
		let { validationObject } = this.state;
		let validationError = false;
		if ('email' in validationObject) {
			if (!validationObject.email) {
				validationObject.email = false;
			}
		}
		else {
			validationObject.email = true;
		}
		if ('firstName' in validationObject) {
			if (!validationObject.firstName) {
				validationObject.firstName = false;
			}
		}
		else {
			validationObject.firstName = true;
		}
		if ('password' in validationObject) {
			if (!validationObject.password) {
				validationObject.password = false;
			}
		}
		else {
			validationObject.password = true;
		}

		if (validationObject.email == true || validationObject.firstName == true || validationObject.password == true) {
			validationError = true;
		}
		this.setState({ validationObject })
		return validationError;
	}
	signUp() {
		var validationError = false;
		var urlName = urlConfig.urlName;
		let validateFieldsError = this.validateFields();
		if (!validateFieldsError) {
			for (var i in this.state.validationObject) {
				if (this.state.validationObject[i]) {
					validationError = true;
					break;
				}
			}

			// if (this.state.password && (this.state.password === this.state.confirmPassword)) {
			if (!validationError && this.state.passwordValid) {
				this.setState({ loading: true })
				SignupAction.signup({
					userId: this.state.email,
					password: this.state.password,
					name: this.state.lastName.length > 0 ? this.state.firstName + ' ' + this.state.lastName : this.state.firstName,
					domainName: urlName,
					// company: this.state.company
				});
			}
			// } else {
			// 	this.checkPasswordMatch();
			// }
		}
	}

	onSignup(e) {
		if (e.key === 13 || e.key === "Enter") {
			this.signUp();
		}
	}

	// checkPasswordMatch() {
	// 	if (this.state.password && this.state.confirmPassword) {
	// 		if (this.state.password !== this.state.confirmPassword) {
	// 			this.setState({
	// 				validationError: true,
	// 				errorMessage: "Passwords do not match!",
	// 			});
	// 		} else {
	// 			this.setState({
	// 				validationError: false,
	// 				errorMessage: "",
	// 			});
	// 		}
	// 	}
	// 	else {
	// 		this.setState({
	// 			validationError: true,
	// 			errorMessage: "Passwords do not match!",
	// 		});
	// 	}
	// }

	validate(field, value) {
		if (field === "email") {
			if (!this.validateEmail(value)) {
				return true;
			} else {
				return false;
			}
		}
		else if (field === 'lastName') {
			return false
		} else if (!value.trim()) {
			return true;
		} else {
			return false;
		}
	}

	validateEmail(email) {
		var emailRegex = new RegExp(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		let isValidEmail = emailRegex.test(email.trim());
		const {
			isBusinessEmail,
			blockedPublicEmailDomains,
			blockedSpamDomains,
			blockedTopLevelDomains,
			blockedDomainErrorMessages,
		} = this.state;
		let mail = email.toLowerCase();
		let domain = mail.split("@");
		const inputTopLevelDomain = mail.split(".");
		let errorMessage = "Please enter a business email address";
		let businessEmailError = false;
		let selectedTLD;
		if (inputTopLevelDomain.length > 1) {
			selectedTLD = inputTopLevelDomain[inputTopLevelDomain.length - 1];
		}
		if (isBusinessEmail) {
			if (
				domain &&
				domain[1] &&
				blockedPublicEmailDomains.includes(domain[1])
			) {
				businessEmailError = true;
				errorMessage =
					(blockedDomainErrorMessages &&
						blockedDomainErrorMessages[1].message &&
						blockedDomainErrorMessages[1].message) ||
					"Please enter a business email address";
			} else if (
				domain &&
				domain[1] &&
				blockedSpamDomains.includes(domain[1])
			) {
				businessEmailError = true;
				errorMessage =
					(blockedDomainErrorMessages &&
						blockedDomainErrorMessages[0].message &&
						blockedDomainErrorMessages[0].message) ||
					"Please enter a business email address";
			}
			if (selectedTLD && blockedTopLevelDomains.includes(selectedTLD)) {
				businessEmailError = true;
				errorMessage =
					(blockedDomainErrorMessages &&
						blockedDomainErrorMessages[2].message &&
						blockedDomainErrorMessages[2].message) ||
					"Please enter a business email address";
			}
		}
		this.setState({ domainErrorMessages: errorMessage });
		if (isValidEmail && !isBusinessEmail) {
			return true;
		} else if (isBusinessEmail && isValidEmail && !businessEmailError) {
			return true;
		} else {
			return false;
		}
	}

	checkPasswordStrength() {
		var tests = schema.validate(this.state.password, {
			list: true,
		});
		this.setState({
			passwordValid:
				tests.length < 2
					? tests.indexOf("min") < 0 && tests.indexOf("spaces") < 0
					: false,
		});
	}
	changelanguage(e) {
		localStorage.setItem("language", e.key);
		this.setState({
			currentlanguage: e.key,
		});
	}
	microsoftSignIn = () => {
		var openWindow = window.open(
			this.state.micUrl,
			"SignIn",
			"width=600,height=400,left=400,top=200,scrollbars=1,status=0"
		);
		if (openWindow == null || typeof openWindow == "undefined") {
			alert("Please disable your popup blocker and try again!", 3);
		}
		setInterval(() => {
			if (cookie.readCookie("micToken")) {
				MicrosoftLoginAction.login(cookie.readCookie("micToken"));
				this.props.history.push("/settings/addAccount");
				cookie.eraseCookie("micToken");
			}
		}, 1000);
	};
	responseGoogle(params) {
		var id_token = params.getAuthResponse().id_token;
		GoogleLoginAction.login(id_token);
	}
	googleLoginButton = () => {
		if (googleKey2domains.indexOf(urlConfig.urlName) > -1) {
			return (
				<GoogleLogin
					clientId="897799599212-5kq3v9n461etdif7n9pkusuupjbumi7n.apps.googleusercontent.com"
					buttonText="Google"
					onSuccess={this.responseGoogle}
					onFailure={this.responseGoogle}
				/>
			);
		} else if (googleKey3domains.indexOf(urlConfig.urlName) > -1) {
			return (
				<GoogleLogin
					clientId="999213825609-6nun3lirf4ck320fkofbhufv28fagjei.apps.googleusercontent.com"
					buttonText="Google"
					onSuccess={this.responseGoogle}
					onFailure={this.responseGoogle}
				/>
			);
		}
		else if (googleKey4domains.indexOf(urlConfig.urlName) > -1) {
			return (
				<GoogleLogin
					// clientId='198165534094-4g51cndfj65rfihcu8in1e87ekf0kacm.apps.googleusercontent.com'
					clientId='999213825609-6nun3lirf4ck320fkofbhufv28fagjei.apps.googleusercontent.com'
					buttonText="Google"
					onSuccess={this.responseGoogle}
					onFailure={this.responseGoogle}
				/>
			);
		} else {
			return (
				<GoogleLogin
					clientId="1043781443422-m2m8c319grmtoufkjqfsq3p0nd74tvun.apps.googleusercontent.com"
					buttonText="Google"
					onSuccess={this.responseGoogle}
					onFailure={this.responseGoogle}
				/>
			);
		}
	};

	domainLogoLoaded = (event) => {
		const target = (event && event.target) || {};
		if (target.style) {
			target.style.opacity = 1;
		}
	};

	domainLogoNotLoaded = (event) => {
		const target = (event && event.target) || {};
		if (target.style) {
			target.style.opacity = 0;
		}
	};
	changePassVisibility = () => {
		this.setState({ showPass: !this.state.showPass })
	}

	render() {
		var urlName = urlConfig.urlName;
		let signupBackground = `url(${this.state.signupBackground})`;
		let domainLogo = this.state.domainLogo;
		var siteTermsMap = {
			"app.localclarity.com": "https://www.localclarity.com/terms-of-service",
			"dev.localclarity.com": "https://www.localclarity.com/terms-of-service",
			"mig.localclarity.com": "https://www.localclarity.com/terms-of-service",
			"localhost:8000": "terms",
			"localhost:3000": "terms",
			"reputationmanager.io": "terms",
			"app.gearseo.com.br": "terms",
			"reviews.insiderperks.com": "terms",
			"reviews.10thousandfeet.com": "terms",
			"gmb.insyghts.io": "terms",
			"reputationmanager.freshfeedback.ca": "terms",
			"app.reputate.co.za": "terms",
			"insights.setmysite.com": "terms",
			"local.mickeyllew.co.za": "terms",
			"reviews.marketingbyrob.com": "terms",
			"places.gvp.digital": "terms",
			"app.bizzrep.com": "https://bizzrep.com/terms-conditions/",
			"app.localclarity.com.br":
				"https://www.localclarity.com/terms-of-service",
			"locations.localorm.com": "terms",
			"app.ormanagement.co": "terms",
			"cloud.mybusiness.tw": "terms",
			"app.beoordeel.online": "terms",
			"canada.localclarity.com":
				"https://www.localclarity.com/terms-of-service",
			"app.localtouch.io": "https://www.localclarity.com/terms-of-service",
			"stage.localclarity.com": "https://www.localclarity.com/terms-of-service",
			"reputation.easyfishmarketing.com": "terms",
			"local.findyouraudience.online": "terms",
			"gmn.astradigital.com.br": "terms",
			"reputation.itsevolve.com": "terms",
			"reviews.flextsms.com": "terms",
			"grow.digimarketer.ca": "terms",
			"local.leadcrossing.com": "terms",
			"reviews.skyeline.com": "terms",
			"reputation.apt.co.ke": "https://docs.google.com/document/d/1wVz-ZCXhVDZyTkk9tAttENx3ZNRKPwsqqfaiCTJUQA8/edit",
			"reviews.engageq.com": "terms",
			"reviews.motivatedmarketing.com": "terms",
			"control.wela.com": "terms",
			"reputation.thatsbiz.com":"terms",
			"app.getlocaladvantage.com":"terms",
			"client.googavis.fr":"terms",
			"app.googlebusiness.ca":'terms',
			'app.hospitality-buddy.eu':'terms',
			'orm.marinemarketingsolutions.net':'terms',
			'footfall.opositive.io':'terms',
			'reply.asterfive.com': 'terms'

		};
		var sitePolicyMap = {
			"app.localclarity.com": "https://www.localclarity.com/privacy-policy",
			"dev.localclarity.com": "https://www.localclarity.com/privacy-policy",
			"mig.localclarity.com": "https://www.localclarity.com/privacy-policy",
			"localhost:8000": "policy",
			"localhost:3000": "policy",
			"reputationmanager.io": "policy",
			"app.gearseo.com.br": "policy",
			"reviews.insiderperks.com": "policy",
			"reviews.10thousandfeet.com": "policy",
			"gmb.insyghts.io": "policy",
			"reputationmanager.freshfeedback.ca": "policy",
			"app.reputate.co.za": "policy",
			"insights.setmysite.com": "policy",
			"local.mickeyllew.co.za": "policy",
			"reviews.marketingbyrob.com": "policy",
			"places.gvp.digital": "policy",
			"app.bizzrep.com": "https://bizzrep.com/privacy-policy/",
			"app.localclarity.com.br": "https://www.localclarity.com/privacy-policy",
			"locations.localorm.com": "policy",
			"app.ormanagement.co": "policy",
			"cloud.mybusiness.tw": "policy",
			"app.beoordeel.online": "policy",
			"canada.localclarity.com": "https://www.localclarity.com/privacy-policy",
			"app.localtouch.io": "https://www.localclarity.com/privacy-policy",
			"stage.localclarity.com": "https://www.localclarity.com/privacy-policy",
			"reputation.easyfishmarketing.com": "policy",
			"local.findyouraudience.online": "policy",
			"gmn.astradigital.com.br": "policy",
			"reputation.itsevolve.com": "policy",
			"reviews.flextsms.com": "policy",
			"grow.digimarketer.ca": "policy",
			"local.leadcrossing.com": "policy",
			"reputation.apt.co.ke": "https://docs.google.com/document/d/1GPYiQsMtchwmJIKlYFdiR8y7zAYKwxGsh9OT69bb4k0/edit",
			"reviews.skyeline.com": "policy",
			"reviews.engageq.com": "policy",
			"reviews.motivatedmarketing.com": "policy",
			"control.wela.com": "policy",
			"reputation.thatsbiz.com":"policy",
			"app.getlocaladvantage.com":"policy",
			"client.googavis.fr":"policy",
			"app.googlebusiness.ca":'policy',
			'app.hospitality-buddy.eu':'policy',
			'orm.marinemarketingsolutions.net':'policy',
			'footfall.opositive.io':'policy',
			'reply.asterfive.com':'policy'
		};
		const menu = (
			<Menu onClick={(e) => this.changelanguage(e.item && e.item.props && e.item.props.value && e.item.props.value)}>
				{_.map(languageDropDown, (item, key) => {
					// if (this.state.languagesList.includes(key)) {
						return <Menu.Item key={key} value={item.value}>{item.label}</Menu.Item>;
					// }
				})}
			</Menu>
		);
		// const user = {};
		return (
			<div className="user-credentials">
				{this.state.signUpPosition == "Right" && (
					<div className="user-info">
						<div className="user-content">
							<div id="style" />
						</div>
					</div>
				)}

				{this.state.signUpPosition == "Default" && (
					<div
						className="user-info"
						style={{ backgroundImage: signupBackground }}
					>
						<div className="info-conent">
							<div className="login-logo">
								<img
									src={domainLogo}
									onError={this.domainLogoNotLoaded}
									onLoad={this.domainLogoLoaded}
								/>
							</div>
						</div>
					</div>
				)}
				{(this.state.signUpPosition == "Default" ||
					this.state.signUpPosition == "Right" ||
					this.state.signUpPosition == "Left") && (
						<div className="user-details">
							{/* <div className="signup-wrap">
								<span className="signup-text">Already have an account?</span>
								<Link to="/login"> Login</Link>
							</div> */}
							{this.state.signUpPosition != "Default" && (
								<div className="marketting-message">
									<div id="message" />
								</div>
							)}
							<div className="user-details__inner">
								<div className='fav-icon-class'>
									<img src={this.state.favIcon}></img>
								</div>
								<div className="language-selector">
									<div>
										<h3>
											{languageFile[localStorage.getItem("language")]["13840"]}
										</h3>
										<span className='signup-text'>
											<span>
												{
													languageFile[
													localStorage.getItem(
														'language'
													)
													]['2069']
												}{' '}

											</span>
											<Link to='/login'>
												{
													languageFile[
													localStorage.getItem(
														'language'
													)
													]['2062']
												}

											</Link>
										</span>
										{/* </> */}
									</div>
									{languageDropDownDomains.indexOf(urlName) > -1 && (
										<Dropdown overlay={menu}>
											<span>
												<div className='translation-icon'>
												{language2letterCode[localStorage.getItem('language')]}
												{getSVG('translate')}
											</div>
											</span>
										</Dropdown>
									)}
								</div>
								{!this.state.signupSuccess && (
									<span className="error-message">{this.state.errorMessage}</span>
								)}
								<ul>
									<div className="name-section">
										<div className="section-items">
											<div className="input-blocks-section">
												<div className="bolck-items">
													<div className='label-block'>{languageFile[
														localStorage.getItem(
															'language'
														)
													][
														'2065'
													]}*</div>

													<Input
														required
														autoComplete="first-name"
														placeholder={
															// 'Your first Name'
															languageFile[
															localStorage.getItem(
																'language'
															)
															][
															'2065'
															]
														}
														onChange={this.userDetailsChange.bind(this, "firstName")}
														value={this.state.firstName}
													/>
												</div>
												<div className="bolck-items">
													<div className='label-block'>{languageFile[
														localStorage.getItem(
															'language'
														)
													][
														'2066'
													]}</div>
													<Input
														autoComplete="last-name"
														placeholder={languageFile[
															localStorage.getItem(
																'language'
															)
														][
															'2066'
														]}
														onChange={this.userDetailsChange.bind(this, "lastName")}
														value={this.state.lastName}
													/>
												</div>

											</div>
										</div>
										{/* <span className="highlight"></span>
										<span className="bar"></span> */}
										{this.state.validationObject.firstName && (
											<span className="error-message">
												{"Please enter a valid name"}
											</span>
										)}
									</div>
									<li>
										{/* <form autoComplete="off"> */}
										<div className='label-block'>{
											languageFile[
											localStorage.getItem('language')
											]['2063']
										}*</div>

										<input
											required
											type="email"
											autoComplete="new-email"
											placeholder={
												languageFile[localStorage.getItem("language")]["10935"]
											}
											onChange={this.userDetailsChange.bind(this, "email")}
											value={this.state.email}
										/>
										{/* </form> */}
										{/* <span className="highlight"></span>
										<span className="bar"></span> */}
										{this.state.validationObject.email && (
											<span className="error-message">
												{this.state.isBusinessEmail
													? this.state.domainErrorMessages
													: "Please enter a valid email"}
											</span>
										)}
									</li>
									{/* <li>
                  <input
                    required
                    autoComplete="userNameField"
                    placeholder={
                      languageFile[localStorage.getItem("language")]["14929"]
                    }
                    onChange={this.userDetailsChange.bind(this, "userName")}
                    value={this.state.userName}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  {this.state.validationObject.userName && (
                    <span className="error-message">
                      Please enter your name
                    </span>
                  )}
                </li> */}
									<li style={{ position: "relative" }}>
										<>
											<div className='label-block'>{
												languageFile[localStorage.getItem("language")]["13123"]
											}*</div>

											<Input
												required
												type={this.state.showPass ? "text" : "password"}
												autoComplete="new-password"

												placeholder={
													languageFile[localStorage.getItem("language")]["13123"]
												}
												// onBlur={this.checkPasswordStrength}
												onChange={this.userDetailsChange.bind(this, "password")}
												value={this.state.password}
												suffix={
													!this.state.showPass ? <span style={{ cursor: 'pointer' }} onClick={() => this.changePassVisibility()}>
														<Tooltip title={
															languageFile[
															localStorage.getItem(
																'language'
															)
															]['2070']
														}>
															<svg viewBox="64 64 896 896" focusable="false" data-icon="eye" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 000 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg></Tooltip>
													</span> :
														<span style={{ cursor: 'pointer' }} onClick={() => this.changePassVisibility()}>
															<Tooltip title={
																languageFile[
																localStorage.getItem(
																	'language'
																)
																]['2071']
															}>
																<svg viewBox="64 64 896 896" focusable="false" data-icon="eye-invisible" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path></svg>
															</Tooltip></span>}
											/>
											{/* <span className="highlight"></span> */}
											{this.state.passwordValid !== undefined &&
												(this.state.passwordValid ? (
													<Tooltip title="Password is valid">
														<span className="password-strength-indicator">
															<Icon
																style={{
																	color: "#00AF00",
																}}
																type="check-square"
															/>
														</span>
													</Tooltip>
												) : (
													<Tooltip visible={true} title={passwordInvalidMessage}>
														<span className="password-strength-indicator">
															<Icon
																style={{
																	color: "#ef4343",
																}}
																type="close-square"
															/>
														</span>
													</Tooltip>
												))}
											{/* <span className="bar"></span> */}
											{this.state.validationObject.password && (
												<span className="error-message">
													Please enter a password
												</span>
											)}
										</>
									</li>

									{/* <li>
										<div className='label-block'>{languageFile[localStorage.getItem("language")]["2067"]}</div>
										<input
											required
											type="text"
											placeholder={languageFile[localStorage.getItem("language")]["2068"]}
											// autoComplete="new-confirmPassword"
											onChange={this.userDetailsChange.bind(
												this,
												"company"
											)}
											value={this.state.company}
										/>
									</li> */}
									<li>
										<Button onClick={this.signUp} loading={this.state.loading}>
											{languageFile[localStorage.getItem("language")]["13840"]}
										</Button>
									</li>
									<li className="login-division">
										<div className="login-with">Or</div>
									</li>
								</ul>

								<div className="login-social">
									{/* <div className="google-btn"><span className="buttonText">Google</span>                                                                                                                                                                                                                  </div                                                                                                                                                                                                                  </div> */}
									<div className="google-btn">{this.googleLoginButton()}</div>
									{/* {this.state.micUrl &&
                                <div className="micro-soft__btn">
                                    <a id="SignIn" onClick={this.microsoftSignIn}>
                                        <span className="microsoft-icon"></span> Microsoft
                        </a>
                                </div>} */}
								</div>
							</div>
							<div className="signup-terms">
								<span className="signup-text" style={{ fontSize: 13 }}>
									{languageFile[localStorage.getItem("language")]["1001"]}
									<a href={siteTermsMap[urlName]} target="_blank">
										{languageFile[localStorage.getItem("language")]["1002"]}
									</a>{" "}
									{languageFile[localStorage.getItem("language")]["1003"]}
									<a href={sitePolicyMap[urlName]} target="_blank">
										{languageFile[localStorage.getItem("language")]["1006"]}
										{languageFile[localStorage.getItem("language")]["1004"]}
									</a>
									{languageFile[localStorage.getItem("language")]["1005"]}
								</span>
							</div>
						</div>
					)}
				{this.state.signUpPosition == "Left" && (
					<div className="user-info">
						<div className="user-content">
							<div id="style" />
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default SignUp;
